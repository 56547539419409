import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import PageTitle from "@/components/PageTitle"
import Icon from "@/components/Icon"
import { faDiscord, faTelegram, faPatreon } from '@fortawesome/free-brands-svg-icons'
import BoostyIcon from '@/images/boosty.png'

export default () => (
  <Layout>
    <Seo title='О проекте'/>
    <PageTitle className='CommonPage_header'>О проекте</PageTitle>
    <section className='CommonPage'>
      <section className='CommonPage_infoBlock'>
        <article className='CommonPage_description'>
          <h2 className='CommonPage_header_2'>Цель проекта</h2>
          <p>Это любительский проект с инструментарием для вождения игр по Dungeons & Dragons (Подземелья и драконы) 5 редакции.</p>
          <p>
            Цель — дать мастеру удобные инструменты, упрощающие вождение игр, вместо листания книг правил и возни с таблицами.
          </p>
        </article>
      </section>
      <section className='CommonPage_infoBlock'>
        <article className='CommonPage_description'>
          <h2 className='CommonPage_header_2'>Обратная связь</h2>
          <p>
            С вопросами и предложениями пишите в <a
              href='https://discord.gg/wqr4eANcDz'
              rel='nofollow noopener noreferrer'
              target='_blank'
              title='Discord-сервер проекта'
            >
              <Icon icon={faDiscord} />
              Discord
            </a> или на <a href="mailto:info@dm-stuff.ru">info@dm-stuff.ru</a>.
          </p>
          <p>
            Релизы публикуются в <a
              href='https://discord.gg/wqr4eANcDz'
              rel='nofollow noopener noreferrer'
              target='_blank'
              title='Discord-сервер проекта'
            >
              <Icon icon={faDiscord} />
              Discord
            </a> и <a
            href='https://t.me/dm_stuff_ru'
            rel='nofollow noopener noreferrer'
            target='_blank'
            title='Telegram-канал проекта'
          >
            <Icon icon={faTelegram} />
            Telegram
          </a>. Там же их можно (и нужно) комментировать.
          </p>
          <p>
            Поддержать проект можно на <a
              href='https://boosty.to/dm-stuff'
              rel='nofollow noopener noreferrer'
              target='_blank'
              title='Страница проекта на Boosty'
            >
              <Icon icon={BoostyIcon} />
              Boosty
            </a> и на <a
              href='https://www.patreon.com/dm_stuff_ru'
              rel='nofollow noopener noreferrer'
              target='_blank'
              title='Страница проекта на Patreon'
            >
              <Icon icon={faPatreon} />
              Patreon
            </a>. Патроны получат специальные роли в <a
              href='https://discord.gg/wqr4eANcDz'
              rel='nofollow noopener noreferrer'
              target='_blank'
              title='Discord-сервер проекта'
            >
              <Icon icon={faDiscord} />
              Discord’е
            </a> и смогут голосовать за новые фичи.
          </p>
        </article>
      </section>
      <section className='CommonPage_infoBlock'>
        <article className='CommonPage_description'>
          <h2 className='CommonPage_header_2'>Терминология</h2>
          <p>Проект использует смешанную терминологию — как от официального локализатора Hobby World (например «испытание», а не «спасбросок»), устоявшуюся фанатскую (например, «хиты» вместо «пунктов здоровья»), так и свою собственную (например «Горезванные» вместо «Угнетённых», или «Удар» вместо «Размашистого удара»).</p>
          <p>Почему так? Потому что мне это кажется более уместным, понятным и простым в употреблении на игре.</p>
        </article>
      </section>
      <section className='CommonPage_infoBlock'>
        <article className='CommonPage_description'>
          <h2 className='CommonPage_header_2'>Благодарности</h2>
          <section>
            <header className='CommonPage_header_3'>Phantom Studio</header>
            <p>Проект DM-Stuff.ru очень много материала взял из перевода «Книги игрока» и «Руководства Мастера» пятой редакции D&D от крутейшей команды <a target="_blank" rel="noopener noreferrer nofollow" href="https://vk.com/phantom_st">Phantom Studio</a>. Они проделали титаническую работу. Большое им спасибо!</p>
            <header className='CommonPage_header_3'>DungeonsAndDragons.ru</header>
            <p>Не меньше материалов взято из переводов книг правил пятой редакции D&D — «Справочника Воло по монстрам», «Тома Морденкайнена о врагах», «Руководства Занатара обо всём» и других, от команды <a target="_blank" rel="noopener noreferrer nofollow" href="https://dungeonsanddragons.ru">DungeonsAndDragons.ru</a>. К сожалению, из-за нехватки редакторов, качество перевода некоторых книг заметно страдает, особенно по сравнению с «Бестиарием», над которым работали обе команды переводчиков одновременно. Поэтому все тексты пришлось вычитывать, прежде чем добавлять на сайт. Но я всё равно им очень благодарен за огромную проделанную работу.</p>
            <header className='CommonPage_header_3'>Dungeon.su</header>
            <p>Отличный проект Игоря Недосекова <a target="_blank" rel="noopener noreferrer nofollow" href="https://dungeon.su">Dungeon.su</a> послужил для DM-Stuff.ru отправной точкой и примером для вдохновения.</p>
            <header className='CommonPage_header_3'>Другие</header>
            <p>DM-Stuff.ru старается ориентироваться на качество генераторов таких замечательных проектов, как «<a target="_blank" rel="noopener noreferrer nofollow" href="https://stormtower.ru">Штормовая башня</a>» Александра Фролова, «<a target="_blank" rel="noopener noreferrer nofollow" href="https://tentaculus.ru">Доктор Тентакуль</a>» и «<a target="_blank" rel="noopener noreferrer nofollow" href="https://www.dungeonmastersvault.com">Dungeon Master’s Vault</a>» (бывший OrcPub). Это — очень хорошие образцы того, как нужно делать генераторы.</p>
          </section>
        </article>
      </section>
      <section className='CommonPage_infoBlock'>
        <h2 className='CommonPage_header_2'>Канал DM-Stuff.ru в Discord</h2>
        <iframe
          src="https://discord.com/widget?id=786045598717444126&theme=dark"
          width="350"
          height="500"
          allowTransparency="true"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
      </section>
    </section>
  </Layout>
)
